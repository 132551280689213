import React from 'react';

function FormLoader() {
  const loaderStyle = {
    border: '4px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '50%',
    borderTop: '4px solid #ffffff',
    width: '24px',
    height: '24px',
    animation: 'spin 1s linear infinite'
  };

  return (
    <div style={loaderStyle}></div>
  );
}

export default FormLoader;
