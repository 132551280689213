import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ProjectProvider } from "../context/ProjectContext";
import { BlogProvider } from "../context/BlogContext";
import { InfoProvider } from "../context/InfoContext";
import Contacts from "../Pages/Contacts";

const Home = lazy(() => import("../Pages/Home"));
const Projects = lazy(() => import("../Pages/Projects"));
const Blogs = lazy(() => import("../Pages/Blogs"));
const BlogDetail = lazy(() => import("../Components/UI/BlogDetail"));

function Routers() {
  return (
    <InfoProvider>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense>
              <Navigate to="/home" />
            </Suspense>
          }
        />
        <Route
          path="/home"
          element={
            <Suspense>
              <Home />
            </Suspense>
          }
        />

        <Route
          path="/projects"
          element={
            <Suspense>
              <ProjectProvider>
                <Projects />
              </ProjectProvider>
            </Suspense>
          }
        />

        <Route
          path="/blogs"
          element={
            <Suspense>
              <BlogProvider>
                <Blogs />
              </BlogProvider>
            </Suspense>
          }
        />
        <Route
          path="/blogs/:id"
          element={
            <Suspense>
              <BlogProvider>
                <BlogDetail />
              </BlogProvider>
            </Suspense>
          }
        />
        <Route path="/contacts" element={<Contacts />} />
      </Routes>
    </InfoProvider>
  );
}

export default Routers;
