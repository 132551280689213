import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { InfoContext } from "../../context/InfoContext";

const quickLinks = [
  {
    path: "/home",
    display: "Home",
  },
  {
    path: "/projects",
    display: "Projects",
  },
  {
    path: "/contacts",
    display: "Contact",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  const { info } = useContext(InfoContext);
  const email = info.length ? info[0].email : "No data available";
  const phone_number = info.length ? info[0].phone_number : "No data available";
  const name = info.length ? info[0].name : "No data available";

  return (
    <footer className="bg-blue-900 text-white py-8 text-center">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-center">
        {/* Quick Links */}
        <div className="flex-grow mb-4 lg:mb-0">
          <h5 className="font-bold mb-4">Quick Links</h5>
          <ul>
            {quickLinks.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.path}
                  className="block hover:text-gray-400 text-white"
                >
                  {item.display}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Social Media Icons */}
        <div className="mt-4 mb-2 lg:mt-0 lg:mx-4 lg:pl-20 flex justify-center">
          <a
            href="https://github.com/NirojBhattarai"
            className="text-white mx-3"
            aria-label="Niroj Bhattarai's GitHub Profile"
          >
            <FontAwesomeIcon icon={faGithub} size="2x" />
          </a>

          <a
            href="https://www.linkedin.com/in/nirojbhattarai/"
            className="text-white mx-3"
            aria-label="Niroj Bhattarai's LinkedIn Profile"
          >
            <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
          </a>
        </div>

        {/* Contact Information */}
        <div className="flex-grow">
          <h5 className="font-bold mb-4">Contact Information</h5>
          <p className="text-sm mb-2">Kathmandu, Nepal</p>
          <p className="text-sm mb-2">Phone Number: {phone_number}</p>
          <p className="text-sm mb-2">Email: {email}</p>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="text-center text-sm mt-4">
        <p>
          &copy; {year} {name}. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
