import React, { useState } from "react";
import axios from "axios";
import FormLoader from "../Components/UI/FormLoader";

function Contacts() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        "https://personal-website-backend-l036.onrender.com/api/contact/contact",
        formData
      );
      setResponseMessage(response.data.message);
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
      setLoading(false);
      setTimeout(() => setResponseMessage(""), 5000); // Hide response message after 5 seconds
    } catch (error) {
      setErrorMessage(
        error.response ? error.response.data.error : "An error occurred"
      );
      setLoading(false); // Stop loading
      setTimeout(() => setErrorMessage(""), 5000); // Hide error message after 5 seconds
    }
  };

  return (
    <div className="bg-slate-900 text-white min-h-screen flex justify-center items-center relative">
      <form
        onSubmit={handleSubmit}
        className="bg-gray-800 p-6 rounded-lg shadow-xl w-full max-w-lg relative"
      >
        <h2 className="text-4xl font-extrabold pt-2 pb-4 text-center">
          Contact Me
        </h2>

        {responseMessage && (
          <div className="mb-4 p-3 bg-green-600 text-white rounded-lg">
            {responseMessage}
          </div>
        )}

        {errorMessage && (
          <div className="mb-4 p-3 bg-red-600 text-white rounded-lg">
            {errorMessage}
          </div>
        )}

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-3 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-3 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Subject</label>
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className="w-full p-3 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-bold mb-2">Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-3 h-32 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          ></textarea>
        </div>

        <div className="flex items-center justify-center">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
            disabled={loading}
          >
            Send
          </button>
          {loading && (
            <div className="ml-3 flex items-center">
              <FormLoader />
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default Contacts;
